var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.companyData,
                      "default-expand-all": "",
                      "current-node-key": _vm.currentNodeKey,
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-tree-node-title" },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "page-search-bar" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.formSearchModel,
                      inline: "",
                      "label-suffix": ": ",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mgb-0",
                        attrs: { label: _vm.$t("lang_title") },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSearch($event)
                            },
                          },
                          model: {
                            value: _vm.formSearchModel.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearchModel, "name", $$v)
                            },
                            expression: "formSearchModel.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mgb-0" },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleSearch },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-refresh",
                              },
                              on: { click: _vm.handleSearchReset },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  disabled: _vm.showAddFlag,
                                  placement: "top",
                                  effect: "light",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "lang_please_select_the_company_on_the_left_before_adding"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                        disabled: !_vm.showAddFlag,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addForm()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  background: "#fff",
                  height: "calc(100% - 62px)",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dutyListLoading,
                        expression: "dutyListLoading",
                      },
                    ],
                    staticClass: "page-pagetable",
                    attrs: {
                      border: "",
                      data: _vm.dutyList,
                      height: "calc(100% - 70px)",
                      "empty-text": _vm.$t("lang_no_data"),
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_title"),
                        align: "center",
                        prop: "name",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_duty_short_name"),
                        align: "center",
                        prop: "shortName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_sort"),
                        align: "center",
                        prop: "sort",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_state"),
                        align: "center",
                        prop: "disabled",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(_vm.$t("lang_enable"))),
                                  ])
                                : _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(_vm._s(_vm.$t("lang_disable_"))),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_operation"),
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    "cancel-button-text":
                                      _vm.$t("lang_cancel_"),
                                    "confirm-button-text":
                                      _vm.$t("lang_determine_"),
                                    title: scope.row.disabled
                                      ? _vm.$t("lang_disable")
                                      : _vm.$t("lang_enable_or_not"),
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.disabledChange(scope.row)
                                    },
                                  },
                                },
                                [
                                  scope.row.disabled
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#007aff" },
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("lang_enable")))]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            color: "rgb(224, 62, 45)",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("lang_disable_"))
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    color: "#007aff",
                                    "margin-left": "10px",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addForm(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    "cancel-button-text":
                                      _vm.$t("lang_cancel_"),
                                    "confirm-button-text":
                                      _vm.$t("lang_determine_"),
                                    title: _vm.$t(
                                      "lang_are_you_sure_to_delete_this_job"
                                    ),
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.remove(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "#e03e2d",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  staticClass: "page-pager",
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.changePageSize,
                    "current-change": _vm.changePage,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.isShowAddEdit
        ? _c("duty-form", {
            attrs: {
              visible: _vm.isShowAddEdit,
              selectCompanyData: _vm.selectCompanyData,
              currtent: _vm.currtent,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              success: _vm.getDutyList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }