




























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getDeptCompanyPage, deleteOrgGroup, updateOrgGroup, getCompanyTreeData, getUserCompany } from '@/api/system/OrganizationApi';
import { DutyModel } from '@/models/organization/DutyModel';
import DutyForm from './duty-form.vue';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'DutyList',
  components: { DutyForm }
})
export default class DutyList extends Vue {
  // 表单搜索Model
  private formSearchModel: any = { name: '' };
  // 职务列表
  private dutyList: DutyModel[] = [];
  // 职务列表加载状态
  private dutyListLoading: boolean = false;

  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  // 当前选择的数据
  currtent: any = null;
  // 是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
  // 公司树列表
  companyData: any[] = [];
  // 当前树选中的gruopId
  groupId: string = '';
  // 选中的公司数据
  selectCompanyData: any = {};
  // 是否显示新增按钮
  showAddFlag: boolean = false;
  // 当前选中的左侧树节点
  currentNodeKey: string = '';


  /**
   * onMounted
   */
  mounted() {
    // 获取公司列表
    this.getParentDeptGroupSelectList();
    this.getUserCompany();
  }

  @Watch('currentNodeKey', { deep: true })
  currentNodeKeyWatcher(id) {
    if (id.toString()) {
        (this.$refs["appTreeRef"] as any).setCurrentKey(id);
      } else {
        (this.$refs["appTreeRef"] as any).setCurrentKey(null);
      }
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    this.selectCompanyData = { ...data };
    if (data.id) {
      this.showAddFlag = true;
      this.groupId = data.id;
      this.pageIndex = 1;
      this.getDutyList();
    }
  }

  /**
   * 获取当前登录人的公司信息
   */
  async getUserCompany() {
    let params = {
      tenantId: UserUtil.getUser().tenantId,
      userId: UserUtil.getUser().id
    };
    let { data } = await getUserCompany(params);
    this.groupId = data[0].id;
    this.currentNodeKey = data[0].id;
    // 获取职务列表
    this.getDutyList();
  }

  /**
   * 启用或禁用
   */
  disabledChange(row) {
    let copyData = { ...row };
    copyData.disabled = !copyData.disabled;
    updateOrgGroup(copyData).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getDutyList();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 添加新增编辑弹框
   */
  addForm(row?: any) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deleteOrgGroup({ groupId: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getDutyList();
      }
    });
  }

  /**
   * 搜索按钮
   */
  handleSearch(e) {
    this.pageIndex = 1;
    this.getDutyList();
  }

  /**
   * 重置搜索按钮
   */
  handleSearchReset() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getDutyList();
  }

  /**
   * 获取职务列表
   */
  getDutyList() {
    let params = {
      name: this.formSearchModel.name,
      type: '2',
      parentId: this.groupId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.dutyListLoading = true;
    getDeptCompanyPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.dutyList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.dutyListLoading = false;
      });
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList() {
    getCompanyTreeData({ type: '0' })
      .then((res: Record<any, any>) => {
        this.companyData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getDutyList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getDutyList();
  }
}
